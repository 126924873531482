

.dashboard_card_glass{
    background: rgb(253 253 253 / 10%);
    box-shadow: 0 0px 12px 0 rgb(208 208 210 / 51%);
    backdrop-filter: blur( 3.5px );
    -webkit-backdrop-filter: blur( 3.5px );
    border-radius: 5px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    width: 300px;
    height: 100px;

    font-family: dmm;
    color: var(--black);
    position: relative;
    display:flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dashboard_chart_card_glass{
    background: rgb(253 253 253 / 10%);
    box-shadow: 0 0px 12px 0 rgb(208 208 210 / 51%);
    backdrop-filter: blur( 3.5px );
    -webkit-backdrop-filter: blur( 3.5px );
    border-radius: 5px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    width: 300px;
    height: 330px;

    font-family: dmm;
    color: var(--black);
    position: relative;
    display:flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
}

.dashboard_chart_card_area{
    position: relative;
    height: 200px;
    width: 300px;
}

.dashboard_chart_card_buttons{
    display: flex;
    font-family: dmm;
    font-size: 10px;
    gap: 5px;
    margin: 5px;

}

.dashboard_chart_card_buttons_item{
    background-color: var(--black);
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.dashboard_chart_card_stats{
    font-family: dmr;
    font-size: 8px;
    display: flex;
    gap: 5px;
    width: 220px;
    justify-content: space-between;
}

.dasboard_card_heading{
    font-family: dmm;
    color: var(--black);
    font-size: 14px;
}

.dasboard_card_content{
    font-family: dmm;
    color: var(--black);
    font-size: 26px;
}


.dashboard_card_flex{
    display: flex;
    gap: 30px;
    justify-content: center;
    padding-bottom: 50px;
    flex-wrap: wrap;
}

.dasboard_main_area{
    width: 1000px;
}

.dasboard_container{
    display: flex;
    justify-content: center;
    height: calc(100vh - 60px);
    overflow-y: scroll;
}

.dasboard_container::-webkit-scrollbar{
    display: none;
}

.dasboard_timer_container{
    margin: 30px 0px;
    display: flex;
}

.dashboard_timer_left{
    font-family: dmr;
    font-size: 14px;
    background-color: var(--black);
    color: white;
    padding: 15px 0px;
    width: 30%;
    display: flex;
    justify-content: center;
    border-radius: 5px 0px 0px 5px;
}

.dashboard_timer_chart_area{
    width: 50%;
  background: #ffffff4a;
  
    border-radius: 0px;
  

    display: flex;
    justify-content: center;
    align-items: center;
}



.dasboard_timer_chart_holder{
    width: 80%;
    display: flex;
    margin-left: 60px;
}

.dasboard_timer_chart_pink{
    height: 5px;
    background-color: var(--pink);

}

.dasboard_timer_chart_black{
    height: 5px;
    background-color: var(--black);

}

.dashboard_timer_counter{
    width: 20%;
     background: #ffffff4a;

    border-radius: 0px 5px 5px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: dmm;
    font-size: 20px;
}

.glass_1{
    flex: 33%;
}
.glass_2{
    flex: 33%;
}
.glass_3{
    flex: 33%;
}
.glass_4{
    flex: 33%;
}

.glass_5{
    flex: 33%;
}

.glass_6{
    flex: 33%;
}

@media only screen and (max-width: 1000px) and (min-width: 700px) {
.dasboard_main_area{
    width: 700px;
    
}



.dasboard_timer_chart_holder{
    margin-left: 0px;
}
}

@media only screen and (max-width: 700px) and (min-width: 320px) {
.dasboard_main_area{
    width: 300px;
}

.glass_1{
    flex: 100%;
}
.glass_2{
    flex: 100%;
}
.glass_3{
    flex: 100%;
}
.glass_4{
    flex: 100%;
}

.glass_5{
    flex: 100%;
}

.glass_6{
    flex: 100%;
}

.dashboard_card_flex{
    display: flex;
    gap: 30px;
    justify-content: center;
    padding-bottom: 50px;
    flex-wrap: wrap;
}

.dasboard_timer_container{
    flex-direction: column;
}

.dashboard_timer_left {
  
    padding: 15px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 5px 5px 0px 0px;
}

.dashboard_timer_chart_area {
    width: 100%;
    background: #ffffff4a;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.dashboard_timer_counter {
    width: 100%;
    background: #ffffff4a;
    border-radius: 0px 0px 5px 5px;
    padding-bottom: 10px;
    opacity: 1;
}

.dasboard_timer_chart_holder{
    margin-left: 0px;
}
}