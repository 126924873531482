.land_header_left{
    display: flex;
    align-items: center;
    font-family: dmm;
    font-size: 14px;
}

.land_logo_main{
    height: 40px;
}

.land_header_left_text{
    margin-left: 5px;
}

.land_main_container{
    display: flex;
    justify-content: center;
}

.land_main_container_inner{
    width: 1250px;
}

.land_header{
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
}

.land_header_right_button{
background-color: var(--black);
display: flex;
padding: 10px 15px;
color: white;
font-family: dmm;
font-size: 14px;
align-items: center;
border-radius: 5px;
}

.land_header_right_button_icon{
    height: 14px;
    margin-left: 5px;
}

.land_hero{
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.land_hero_inner{
    width: 1020px;
    display: flex;
    justify-content: space-between;
}

.land_hero_top{
    display: flex;
    font-family: dmm;
    font-size: 14px;
}

.land_hero_icon{
width: 50px;
margin-right: 15px;
}

.land_hero_title{
    margin-top: 20px;
    font-family: acx;
    font-size: 32px;
}

.land_hero_caption{
    margin-top: 10px;
    font-family: dml;
    font-size: 14px;
}

.land_hero_button{
margin-top: 20px;
background-color: var(--land);
display: flex;
padding: 10px 15px;
color: white;
font-family: dmm;
font-size: 14px;
align-items: center;
border-radius: 5px;
width: fit-content;
}

.land_hero_right_image{
    height: 250px;
}

.land_section_2{
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.land_section_2_inner{
    width: 1020px;
    display: flex;
    justify-content: space-between;
}

.land_section_2_item1{
    width: 250px;
    background-color: white;
    padding: 10px 20px;
}

.land_section_2_item_head{
    font-family: dmm;
    font-size: 21px;
}

.land_section_2_item_caption{
    font-family: dmm;
    font-size: 14px;
}

.land_section_2_item2{
        width: 250px;
    background-color: white;
    padding: 10px 20px;
}

.land_section_2_item3{
           width: 300px;
    background-color: var(--land);
    padding: 10px 20px;
    color: white;
}
.land_section_3{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.land_section_3_inner{
        width: 1020px;
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}

.land_section_3_right_title{
    font-family: acx;
    font-size: 32px;
}

.land_section_3_image{
    height: 350px;
}

.land_section_3_right_content{
    width: 400px;
    font-family: dml;
    font-size: 14px;
}

.land_marquee_flex{
    display: flex;
    font-family: acx;
    font-size: 32px;
    align-items: center;

}

.land_marquee_icon{
    height: 15px;
    margin: 10px 30px;
}

.land_marquee{
    margin: 30px 0px;
}

.land_sec_3{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.land_sec_3_inner{
    width: 1020px;
    display: flex;
    justify-content: space-between;
}

.land_sec_3_item1{
    width: 280px;
    padding: 20px;
    font-family: dmm;
    font-size: 14px;
    background-color: white;
    display: flex;
    align-items: center;
}
.land_sec_3_item2{
    width: 280px;
    padding: 20px;
    font-family: dmm;
    font-size: 14px;
    background-color: white;
        display: flex;
    align-items: center;
}
.land_sec_3_item3{
    width: 280px;
    padding: 20px;
    font-family: dmm;
    font-size: 14px;
    background-color: var(--land);
    color: white;
        display: flex;
    align-items: center;
}

.land_sec_4{
        display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.land_sec_4_inner{
    width: 1020px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.land_sec_4_left{
    width: 500px;
}

.land_sec_4_left_title{
    font-family: acx;
    font-size: 32px;
}

.land_sec_4_left_content{
font-family: dml;
font-size: 14px;
}

.land_sec4_image{
    height: 250px;
}
.land_sec_5{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.land_sec_5_inner{
    width: 1020px;

}
.land_sec_5_item_no{
    font-family: dmm;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.land_sec_5_item_title{
    font-family: acx;
    font-size: 18px;
    margin-bottom: 10px;
}

.land_Sec_5_item_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.land_sec_5_icon{
    width: 25px;
}
.land_sec_5_item_content{
    font-family: dml;
    font-size: 14px;
    margin-bottom: 10px;
}
.land_sec_6{
    display: flex;
    justify-content: center;
    margin-top: 150px;
    margin-bottom: 30px;
 
}
.land_sec_6_inner{
    width: 1020px;
    background-color: white;
    padding: 20px;
}
.land_sec_6_item{

    position: relative;
}
.land_sec_6_icon_holder{
    position: absolute;
    top: -100px;
    left: 200px;
    z-index: -1;
}
.land_sec_6_icon{
    width: 40vw;
}
.land_sec_6_title{
    font-family: acx;
    font-size: 32px;
    display: flex;
    justify-content: center;
}
.land_sec_6_content{
    font-family: dml;
    font-size: 14px;
}
.land_sec_7{
    display: flex;
    justify-content: center;
    background-color: white;
    margin-top: 50px;
}
.land_sec_7_inner{
    width: 1020px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.land_sec_7_left_icon{
    height: 130px;
}
.land_sec_7_right_icon{
    height: 130px;
}
.land_sec_7_content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.land_sec_7_title{
    font-family: acx;
    font-size: 32px;
    text-align: center;
    margin-top: 30px;
}
.land_hero_button_black{
margin-top: 20px;
background-color: var(--black);
display: flex;
padding: 10px 15px;
color: white;
font-family: dmm;
font-size: 14px;
align-items: center;
border-radius: 5px;
width: fit-content;
}

.land_sec_8{
    display: flex;
    justify-content: center;
       background-color: white;
}
.land_sec_8_inner{
    width: 1020px;
    display: flex;
    justify-content: space-evenly;
    font-family: dmm;
    font-size: 14px;
    padding-top: 60px;
    padding-bottom: 30px;
    flex-wrap: wrap;
}



@media only screen and (max-width: 1024px ) and (min-width: 930px){
    .land_main_container_inner{
    width: 1000px;
}

.land_sec_6_inner{
    width: 930px;
}
.land_sec_6_icon_holder{
    position: absolute;
    top: -80px;
    left: 240px;
    z-index: -1;
}

.land_hero_inner{
    width: 930px;
    display: flex;
    justify-content: space-between;
}

.land_section_2_inner{
    width: 930px;
}

.land_section_2_item3{
    width: 275px;
}
.land_section_2_item2{
width: 230px;
}

.land_section_2_item1{
    width: 230px;
}

.land_section_3_inner{
        width: 930px;
   
}

.land_sec_4_inner{
    width: 930px;
}
.land_sec_5_inner{
    width: 930px;

}
.land_sec_7_inner{
    width: 930px;
  
}





}

@media only screen and (max-width: 930px ) and (min-width: 700px){
        .land_main_container_inner{
    width: 96vw;
}
.land_hero_inner{
    width: 90vw;
    display: flex;
    justify-content: space-between;
}
.land_hero_title{
    margin-top: 20px;
    font-family: acx;
    font-size: 21px;
}
.land_hero_right_image{
    height: 200px;
}
.land_section_2_inner{
    width: 90vw;
    display: flex;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap;
}
.land_section_3_inner{
        width: 90vw;
   
}
.land_section_3_image{
    height: 250px;
}
.land_section_3_right_title{
    font-family: acx;
    font-size: 21px;
    margin-bottom: 10px;
}
.land_section_3_right_content{
    width: 350px;
    font-family: dml;
    font-size: 14px;
}
.land_marquee_flex{
    display: flex;
    font-family: acx;
    font-size: 21px;
    align-items: center;

}
.land_sec_3_inner{
    width: 90vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px;
}
.land_sec_4_inner{
    width: 90vw;
    flex-direction: column;
    gap: 30px;
}
.land_sec_4_left{
    width: 600px;
}

.land_sec_4_left_title{
    font-family: acx;
    font-size: 21px;
    margin-bottom: 10px;
}
.land_sec_5_inner{
    width: 90vw;

}
.land_sec_6_inner{
    width: 90vw;
}
.land_sec_6_icon_holder{
    position: absolute;
    top: -70px;
    left: 200px;
    z-index: -1;
}
.land_sec_6_title{
    font-family: acx;
    font-size: 21px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}
.land_sec_7_title{
    font-family: acx;
    font-size: 21px;
    text-align: center;
    margin-top: 30px;
}
}



@media only screen and (max-width: 426px ) and (min-width: 320px){
          .land_main_container_inner{
            
    width: 96vw;
}
.land_hero_inner{
     width: 90vw;
    display: flex;
    flex-direction: column;
}
    .land_hero_title{
    margin-top: 20px;
    font-family: acx;
    font-size: 18px;
}
.land_hero_right_image_container{
 display: none;
}
.land_section_2_inner{
    width: 90vw;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    gap: 20px;
   align-items: center;
}
.land_section_3_inner{
        width: 90vw;
   flex-direction: column;
}
.land_section_3_image{
    height: 250px;
}
.land_section_3_right_title{
    font-family: acx;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.land_marquee_flex{
    display: flex;
    font-family: acx;
    font-size: 18px;
    align-items: center;

}
.land_sec_3_inner{
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.land_sec_4_inner{
    width: 90vw;
    flex-direction: column;
    gap: 30px;
}
.land_sec_4_left{
    width: 280px;
}
.land_sec4_image{
    width: 320px;
}
.land_section_3_right_content{
    width: 300px;
}
.land_sec_4_left_title{
    font-family: acx;
    font-size: 18px;
    margin-bottom: 10px;
}
.land_sec_5_inner{
    width: 90vw;

}
.land_sec_6_inner{
    width: 90vw;
}
.land_sec_6_icon_holder{
    position: absolute;
    top: -60px;
    left: 30px;
    z-index: -1;
}
.land_sec_6_title{
    font-family: acx;
    font-size: 18px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.land_sec_6_icon{
    width: 70vw;
}

.land_sec_6{
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 30px;
 
}
.land_sec_7_title{
    font-family: acx;
    font-size: 18px;
    text-align: center;
    margin-top: 30px;
}
.land_sec_7_left_icon{
    height: 60px;
}
.land_sec_7_right_icon{
    height: 60px;
}
.land_sec_8_inner{
    width: 90vw;
    display: flex;
    justify-content: space-evenly;
    font-family: dmm;
    font-size: 14px;
    padding-top: 60px;
    padding-bottom: 30px;
    flex-wrap: wrap;
    gap: 20px;
}
.land_hero_button_container{
    display: none;
}

}


@media only screen and (max-width: 700px ) and (min-width: 426px){
    
        .land_main_container_inner{
            
    width: 96vw;
}
.land_hero_inner{
     width: 90vw;
    display: flex;
    flex-direction: column;
}
.land_hero_title{
    margin-top: 20px;
    font-family: acx;
    font-size: 24px;
}
.land_hero_right_image_container{
 display: none;
}

}