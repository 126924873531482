.farms_main_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
}

.farms_main_container{
        display: flex;
    justify-content: center;
    height: calc(100vh - 60px);
    overflow-y: scroll;
    align-items: center;
}

.farms_main_area{
    width: 1000px;
}

.farms_main_container::-webkit-scrollbar{
    display: none;
}

.farms_not_created{
    font-family: dmm;
    font-size: 14px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.farms_not_created_inner{
   
    width: 300px;
    height: 300px;
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media only screen and (max-width: 1000px) and (min-width: 700px) {
.farms_main_area{
    width: 700px;
    
}
}

@media only screen and (max-width: 700px) and (min-width: 320px) {
    .farms_main_area{
    width: 300px;
}

.farms_main_container{
    align-items: unset;
}
}
