.farmcard_container{
     background: rgb(253 253 253 / 10%);
    box-shadow: 0 0px 12px 0 rgb(208 208 210 / 51%);
    backdrop-filter: blur( 3.5px );
    -webkit-backdrop-filter: blur( 3.5px );
    border-radius: 5px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    width: 300px;
    margin: 30px 0px;

}

.farmcard_header_flex{
    display: flex;
    background-color: var(--black);
    padding: 10px;
    color: white;
    justify-content: space-between;
    align-items: center;
    font-family: dmr;
    border-radius: 5px;
    filter:drop-shadow(3px 0px 6px var(--pink))
}




.farmcard_header_right{
    background-color: var(--pink);
    padding: 5px;
    border-radius: 5px;
    color: var(--black);
    font-family: dmm;
}

.farmcard_content_area{
    padding: 10px 10px;
    font-family: dmm;
    font-size: 14px;
}

.farmcard_content_item{
    display: flex;
    justify-content: space-between;
    margin: 5px 0px;
}

.farmcard_hr_container{
    padding: 0px 10px;
    display: flex;
    justify-content: center;
}

.farmcard_hr{
    width: 100%;
      border-bottom-style: solid;

}

.farmcard_harvest_container{
    padding: 10px;
}

.farmcard_harvest_topic{
    font-family: dmm;
    font-size: 14px;
}

.farmcard_harvest_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: dmm;
}

.farmcard_harvest_left{
    font-size: 24px;
}

.farmcard_harvest_right{
    background-color: var(--pink);
    border-radius: 5px;
    padding: 5px;
        font-family: dmm;
    font-size: 14px;
    height: 20px;
}

.farmcard_submit_button{
    background-color: var(--black);
    color: white;
    font-family: dmm;
    font-size: 14px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
    border-radius: 5px;
    height: 20px;
}