.lands_main{
    background-color: white;
    width: 100%;
}

.lands_hero_flex{
    display: flex;
        justify-content: space-between;
    height: 650px;
    align-items: center;
    position: relative;
}

.lands_hero_left{
    margin-left: 50px;
}
.lands_hero_right{
    margin-right: 50px;
}

.lands_hero_title{
    font-family: mu;
    font-size: 96px;
    line-height: 85px;
     position: relative;
    z-index: 2;
}

.lands_hero_left_links{
    display: flex;
    font-family: psb;
    font-family: 18px;
    gap: 50px;
    margin-top: 20px;
    position: relative;
    z-index: 2;
}

.lands_hero_left_links_item{
    display: flex;
    gap: 10px;
}

.lands_hero_right_div{
  width: 200px;
    height: 320px;
    background-color: #FED2CB;
    position: relative;
    margin-right: 50px;
}

.lands_main{
    max-width: 1400px;

}

.lands_header_main{
    background-color: white;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.lands_header_links_item{
    display: flex;
    align-items: center;
    font-family: psb;
    gap: 10px;
    margin-right: 50px;
}
.lands_header_logo{
    height: 35px;
    margin-left: 45px;
}
.lands_header_icons{
    height: 12px;
}
.lands_top{
    display: flex;
    justify-content: center;
}

.lands_hero_main{
    background-color: white;
    width: 100%;
}

.lands_hero_bull{
    height: 300px;
    position: absolute;
    top: -70px;
    left: -140px;
    z-index: 5;
}

.lands_hero_salt{
     height: 210px;
    position: absolute;
    bottom: -80px;
    right: -80px;
}

.lands_hero_waves{
    position: absolute;
    bottom: 0;
}

.lands_hero_waves {
    position: absolute;
    bottom: -245px;
    z-index: 1;
}

.lands_marquee_flex{
    display: flex;
    font-family: mu;
    font-size: 21px;
    align-items: center;
    margin: 5px 0px;
    letter-spacing: 0.3px;
    z-index: 5;
    position: relative;

}
.lands_about_us_main{
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    position: relative;
    height: 400px;
    align-items: center;
    margin-bottom: 60px;
}
.land_about_us_logo{
    width: 180px;
    position: relative;
    z-index: 4;
}
.land_about_us_title{
    font-family: mu;
    font-size: 96px;
    line-height:85px;
        position: relative;
    z-index: 4;
}
.land_about_us_content{
    width: 600px;
    font-family: psr;
    font-size: 18px;
    margin-top: 15px;
        position: relative;
    z-index: 4;
}

.land_about_us_content_item{
    display: flex;
    align-items: center;
    font-family: psb;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 15px;
        position: relative;
    z-index: 4;
}
.land_about_us_waves{
    position: absolute;
    bottom: -60px;
    width: -webkit-fill-available;
    z-index: 2;
}
.lands_pointers_main{
    background-color: #FED2CB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
}

.lands_pointers_item_white{
    background-color: white;
    width: 600px;
    padding: 20px 50px;
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 20px;
    border-radius: 5px;
}
.lands_pointers_item_black{
       background-color: black;
    width: 600px;
    padding: 20px 50px;
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 20px;
    border-radius: 5px;
    color: white;
}
.lands_pointers_left{
    font-family: mu;
    font-size: 96px;
}
.lands_pointers_right{
    font-family: psr;
}

.land_how_it_works_main{
    display: flex;
    justify-content: space-around;
    align-items: center;
margin-top: 50px;
margin-bottom: 50px;
}
.land_how_it_works_left {
    position: relative;
}
.land_how_it_works_bg {
    height: 400px;
}
.land_how_it_works_stone {
    height: 300px;
    position: absolute;
    left: 20px;
    bottom: 30px;
}
.land_how_it_works_right{
    width: 500px;
    margin-right: 100px;
}
.land_tokonomics_main{
    background-color: black;
    padding: 50px;
    color: white;
}
.land_tokonomics_title{
    font-family: mu;
    font-size: 96px;
}
.land_tokonomics_left_item {
    width: 300px;
    background-color: white;
    color: black;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'psb';
    border-radius: 5px;
    margin: 10px;
}
.land_tokonomics_flex{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
}

.lands_tokonomics_icon{
    height: 200px;
}

.lands_pointers2_title{
    font-family: mu;
    font-size: 96px;
}
.lands_pointers2_main{
    padding: 50px;
}

.lands_pointers2_item_title{
    font-family: psb;
}
.lands_pointers2_item_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.lands_pointers2_icon{
    height: 25px;
}
.lands_pointers2_item_content{
    font-family: psr;
    margin-bottom: 10px;
}

.lands_konst_main{
    padding: 10px 50px;
    margin-bottom:30px
}
.lands_konst_title{
    font-family: mu;
    font-size: 96px;
}
.lands_konst_content{
    font-family: psr;
}
.lands_footer_main{
    background-color: black;
    color: white;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.lands_footer_flex{
    display: flex;
    align-items: center;
}
.lands_footer_content{
    font-family: mu;
    font-size: 24px;
    text-align: center;
}
.lands_footer_content_icon{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
}
.lands_footer_content_icon_right{
    height: 10px;
}
.lands_sec_8_inner{
    display: flex;
    font-family: psb;
    justify-content: space-around;
}
.lands_sec_8{
    padding: 20px;
}

@media only screen and (max-width: 830px ) and (min-width: 700px){
    .lands_header_logo{
        margin-left: 15px;
    }
    .lands_header_links_item{
        margin-right: 20px;
    }
    .lands_hero_flex{
        height: 450px;
    }
    .lands_hero_left{
        margin-left: 20px;
    }
    .lands_hero_title{
        font-size: 76px;
        line-height: 75px;
    }
    .lands_hero_right_div{
        margin-right: 35px;
            height: 280px;
    width: 170px;
    }
    .lands_hero_bull{
            height: 250px;
    position: absolute;
    top: -60px;
    left: -120px;
    z-index: 5;
    }
    .lands_hero_salt {
    height: 170px;
    position: absolute;
    bottom: -80px;
    right: -40px;
}
.lands_hero_waves {
    position: absolute;
    bottom: -195px;
    z-index: 1;
    width: 100vw;
}
.land_about_us_title{
    font-family: mu;
    font-size: 76px;
    line-height:75px;
        position: relative;
    z-index: 4;
}
.land_about_us_content{
    width: 400px;
}
.lands_about_us_main{
    display: flex;
    justify-content: space-around;
    margin-top: 0px;
    position: relative;
    height: 400px;
    align-items: center;
    margin-bottom: 30px;
}
.land_about_us_waves {
    position: absolute;
    bottom: -30px;
    width: -webkit-fill-available;
    z-index: 2;
}
.land_how_it_works_main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    flex-direction: column;
    gap: 50px;
}
.land_how_it_works_right {
    width: max-content;
    margin-right: 0px;
}
.land_tokonomics_title{
    font-family: mu;
    font-size: 76px;
}
.lands_pointers2_title{
    font-size: 76px;
     margin-bottom: 20px;
}
.lands_konst_title{
    font-family: mu;
    font-size: 76px;
}
.lands_footer_icon{
    height: 130px;
}
.lands_footer_content{
    font-size: 20px;
}
}



@media only screen and (max-width: 426px ) and (min-width: 320px){
    .lands_header_logo{
        margin-left: 20px;
    }
    .lands_header_links_item{
        margin-right: 20px;
    }
    .lands_hero_flex {
    display: flex;
    justify-content: center;
    height: 650px;
    align-items: center;
    position: relative;
    flex-direction: column;
    gap: 80px;
}
.lands_hero_left{
    margin-left: 0px;
}
.lands_hero_title {
    font-family: mu;
    font-size: 46px;
    line-height: 45px;
    position: relative;
    z-index: 2;
}
.lands_hero_left_links {
    display: flex;
    font-family: psb;
    font-family: 18px;
    grid-gap: 50px;
    gap: 50px;
    margin-top: 20px;
    position: relative;
    z-index: 2;
    justify-content: center;
}
.lands_hero_right{
    margin-right: 0px;
}
.lands_hero_right_div {
    width: 180px;
    height: 260px;
    background-color: #FED2CB;
    position: relative;
     margin-right: 0px;
}
.lands_hero_bull {
    height: 250px;
    position: absolute;
    top: -60px;
    left: -120px;
    z-index: 5;
}
.lands_hero_salt {
    height: 170px;
    position: absolute;
    bottom: -80px;
    right: -80px;
    z-index: 5;
}
.lands_hero_waves {
    position: absolute;
    bottom: -107px;
    z-index: 1;
    width: 100vw;
}
.lands_about_us_main {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    position: relative;
    align-items: center;
    margin-bottom: 60px;
    flex-direction: column-reverse;
    gap: 30px;
    height: fit-content;
}
.land_about_us_logo {
    width: 130px;
    position: relative;
    z-index: 4;
}
.land_about_us_title {
    font-family: mu;
    font-size: 46px;
    line-height: 45px;
    position: relative;
    z-index: 4;
}
.land_about_us_content {
    width: 300px;
    font-family: psr;
    font-size: 18px;
    margin-top: 15px;
    position: relative;
    z-index: 4;
}
.lands_pointers_item_white {
    background-color: white;
    width: 250px;
    padding: 20px 50px;
    display: flex;
    align-items: center;
    margin: 20px;
    border-radius: 5px;
    flex-direction: column;
    gap: 0px;
}
.lands_pointers_item_black {
    background-color: black;
    width: 250px;
    padding: 20px 50px;
    display: flex;
    align-items: center;
    margin: 20px;
    border-radius: 5px;
    flex-direction: column;
    gap: 0px;
}
.lands_pointers_left{
    font-size: 45px;
}
.land_how_it_works_main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    flex-direction: column;
    gap: 50px;
}
.land_how_it_works_right {
    width: max-content;
    margin-right: 0px;
}
.land_tokonomics_title{
    font-family: mu;
    font-size: 46px;
}
.land_tokonomics_flex {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
    flex-direction: column-reverse;
    gap: 30px;
}
.land_tokonomics_main {
    background-color: black;
    padding: 50px 10px;
    color: white;
}
.lands_tokonomics_icon{
    height: 175px;
}
.lands_pointers2_title{
    font-size: 46px;
    margin-bottom: 20px;
}
.lands_pointers2_main {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
}
.lands_konst_title{
    font-family: mu;
    font-size: 46px;
    margin-bottom: 10px;
}
.lands_konst_main{
    padding: 10px 20px;
}
.lands_footer_icon{
    height: 70px;
}
.lands_footer_content{
    font-size: 12px;
}
.lands_footer_main{
    padding: 20px;
}
.lands_footer_content_icon {
 margin-top: 10px;
    font-size: 12px;
}
.lands_footer_content_icon_right {
    height: 8px;
}
.lands_sec_8_inner {
    display: flex;
    font-family: psb;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;
}
}