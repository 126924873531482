.header_container{
    height: 60px;
    display: flex;
    justify-content: space-between;
}

.header_logo{
    height: 40px;
}

.header_logo_container{
    display: flex;
    align-items: center;
    font-family: dmm;
    font-size: 14px;
    gap: 5px;
    margin-left: 10px;
}

.header_links_container{
    display: flex;
    font-family: dmm;
    font-size: 14px;
    gap: 30px;
    align-items: center;
}

.header_links_item_selected{
    text-decoration: underline;
     text-underline-position: under;
     text-decoration-thickness: 2px;
}

.header_links_item{
    color: #808680;
}

.header_connect_button{
    font-family: dmm;
    font-size: 14px;
    background-color: var(--black);
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    border-radius: 5px;
}

.header_connect_button_container{
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.header_menu_icon{
    display: none;
}

@media only screen and (max-width: 426px) {

.header_logo_text{
    display: none;
}

.header_menu_icon{
    display: unset;
    height: 30px;
}

.header_logo{
    display: none;
}

.header_links_container{
    display: none;
}

.header_main{
    position: relative;
}

.header_menu_container{
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 20;

}

a {
    text-decoration: none;
    color: #000000;
}

.header_logo_menu_container{
    display: flex;
   justify-content: center;
}

.header_menu_logo{
    height: 40px;
    margin-top: 10vh;
}

.header_menu_item_selected{
   
    text-decoration: underline;
     text-underline-position: under;
     text-decoration-thickness: 2px;
}

.header_menu_item{
        color: #808680;
}

.header_menu_item_container{
    display: flex;
    flex-direction: column;
    gap: 25px;
    font-family: dmm;
    font-size: 18px;
    align-items: center;
}

.header_menu_flex{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
}

.header_menu_close_flex{
    display: flex;
    align-items: center;
    font-family: dmm;
    font-size: 14px;
    background-color: var(--pink);
    padding:  8px;
    border-radius: 25px;
}

.header_menu_close_container{
    display: flex;
    justify-content: center;
    margin-bottom: 10vh;
}

.header_menu_close_logo{
    height: 30px;
}




}