*{
  margin: 0;
  padding: 0;
}

:root {
  --black:#1B2122;
  --bgpink:#f1c9c238;
  --pink:#F2CBC5;
  --land:#FED2CB;
  background-color: #f1c9c238;
}

@font-face {
  font-family: dmm;
  src: url('./fonts/DMMono-Medium.ttf');
}

@font-face {
  font-family: dmr;
  src: url('./fonts/DMMono-Regular.ttf');
}

@font-face {
  font-family: dml;
  src: url('./fonts/DMMono-Light.ttf');
}

@font-face {
  font-family: acx;
  src: url('./fonts/Airbnb.ttf');
}

@font-face {
  font-family: mu;
  src: url('./fonts/MonumentExtended-Ultrabold.otf')
}

@font-face {
  font-family: psr;
  src: url('./fonts/Product Sans Regular.ttf')
}

@font-face {
  font-family: psb;
  src: url('./fonts/Product Sans Bold.ttf')
}



.bgcontain{
  position: absolute;
}

.bgwaves{
  height: 96vh;
  width: 99vw;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

a{
    text-decoration: none;
    color: #000000;
}

.bgsphere{
  height: 40vw;
  width: 40vw;
  object-fit: cover;
  position: absolute;
  top: calc(50vh - 20vw);
  left: 30vw;
  z-index: -1;
}

.content_container{
  height: 100vh;
  width: 100vw;
}

@media only screen and (max-width: 768px) {
.bgsphere{
  height: 50vw;
  width: 50vw;
  object-fit: cover;
  position: absolute;
  top: calc(50vh - 25vw);
  left: 25vw;
}
}

@media only screen and (max-width: 426px) {
.bgsphere{
     height: 60vw;
    width: 60vw;
    object-fit: cover;
    position: absolute;
    top: calc(50vh - 30vw);
    left: 20vw;
}
}

@media only screen and (max-width: 4000px ) and (min-width: 1490px){
.bgsphere{
     height: 450px;
    width: 450px;
    object-fit: cover;
    position: absolute;
    top: calc(50vh - 225px);
    left: calc(50vw - 225px);
}


.bgwaves{
  height: 96vh;
  width: 1490px;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: calc((100vw - 1490px)/2);
}

.content_container{
  max-width: 1490px;
  position: absolute;
  top: 0;
  left: calc((100vw - 1490px)/2);

}
}
